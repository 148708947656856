







































































































































































































































































































import api from "@/api";
import {Component, Ref, Vue} from "vue-property-decorator";
import {
  AuditFlowScope, AuditUserLogStatus,
  HelpAppliesDto,
  SelectListItem,
  UserAuditInput,
  VolunteerDtoPagedResultDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import {regionData} from "element-china-area-data";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import ElOptionSpan from "@/components/AbSelect/elOptionSpan.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import RejectDialog from "@/components/RejectDialog/index.vue";

@Component({
  components:
    {
      ExportButton,
      ElOptionSpan,
      SimpleUploadImage,
      AgileAuditTag,
      AbSelect,
      PagedTableView,
      RejectDialog,
    }
})
export default class HelpAppliesList extends Vue {
  @Ref() readonly pagedTableView!: any;
  @Ref() backDialogBatch!: RejectDialog;

  editId = 0;
  queryForm = {
    name: "",
    phone: "",
    title: "",
    volunteerName: "",
    provinceId: undefined,
    cityId: undefined,
    auditStatus: undefined,
    areaId: undefined,
    tobeOrganizationUnitId: undefined,
    tobeVolunteerId: undefined,
    introducerVolunteerId: undefined,
  };
  selectedOptions: string[] = ["", "", ""];
  options: any = regionData;
  visible = false;
  auditStatusList: any[] = [];
  exportFieldList=["求助方类型",
    "求助人",
    "性别",
    "出生日期",
    "联系电话",
    "服务地点",
    "身体状况",
    "服务对象类型",
    "审核状态"]
  fetchData(params: any) {
    return api.helpApplies.getAll(params);
  }

  typeList: SelectListItem[] = [];

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "AuditStatus",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  async created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await api.helpApplies.get({id: this.dataId}).then((res) => {
        this.form = {...res};
        this.selectedOptions = [
          res!.provinceId + "",
          res!.cityId + "",
          res!.areaId + ""
        ];
      });
    }
    this.getUnitList();
    this.getVolunteerList();
    this.fetchEnumType();
  }

  unitList: any = [];

  getUnitList() {
    api.organizationUnit.getAllApproved().then((res) => {
      this.unitList = res;
    });
  }

  // 跳转详情页
  handleDetail(index: number, row: HelpAppliesDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "helpAppliesDetail",
      params: {id: row.id!.toString()}
    });
  }

  // 跳转详情页
  handlePushList( row: HelpAppliesDto) {
    this.$router.push({
      name: "serviceMaintain",
      query:{
        serviceObjectIds:row.serviceObjectIds
      }
    });
  }

  handleDistributionOrg(row: HelpAppliesDto) {
    this.$confirm("确定要分配给该机构审核吗?", "提示").then(async () => {
      await api.helpApplies.distributionOrg({applyId: row.id}).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.fetchData(this.queryForm);
      });
    });
  }

  orgId = "";
  rowId = undefined;

  handleUpdateDistributionOrg() {
    if (this.orgId) {
      this.$confirm("确定要分配给该机构审核吗?", "提示").then(async () => {
        await api.helpApplies.distributionAndUpdateOrg({
          body: {
            tobeOrganizationUnitId: parseInt(this.orgId),
            id: this.rowId
          }
        }).then((res) => {
          this.visible = false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });

          this.fetchData(this.queryForm);
        });
      });
    } else {
      this.$message({
        type: "error",
        message: "请选择机构!",
      });

    }

  }

  handleShowLog(row: any) {
    this.visible = true;
    this.rowId = row.id
  }

  //服务管理
  handleServiceManage(row: any) {
    this.$router.push(
      {
        name: "serviceMaintain-edit",
        params: {
          id: row.id!.toString(),
        },
        query: {
          isHelpApplies: "true"
        }
      }
    );
  }

  volunteerList: any = [];

  getVolunteerList() {
    api.volunteer.getAll({maxResultCount: 65535}).then((res: VolunteerDtoPagedResultDto) => {
      this.volunteerList = [...res.items!];
    })
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.HelpApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  handleChange(value: any, label: any) {
    this.queryForm!.provinceId = value[0];
    this.queryForm!.cityId = value[1];
    this.queryForm!.areaId = value[2];
  }

  getProvince(id: any) {
    api.province.get({id: id}).then((res) => {
      this.provinceName = res.provinceName!;
    });
    return this.provinceName;
  }

  getCity(id: any) {
    api.city.get({id: id}).then((res) => {
      this.cityName = res.cityName!;
    });
    return this.cityName;
  }

  getArea(id: any) {
    api.area.get({id: id}).then((res) => {
      this.areaName = res.areaName!;
    });
    return this.areaName;
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }

  selection: UserAuditInput[] = [];

  handleSelectionChange(e: HelpAppliesDto[]) {
    this.selection = [];

    if (e.length > 0) {
      this.selection = e.map((x: HelpAppliesDto) => {
        return {
          hostId: x.id + "",
          auditStatus: x.auditStatus,
          auditFlowId: x.auditFlowId,
        };
      });
      console.log("selection is :", this.selection);
    }
  }

  selectable(row: any, index: any) {
    return row.isCanAudit === true;
  }

  handleBatchAudited() {
    let postData = this.selection.map((x) => {
      x.desc = "批量审核通过";
      x.status = AuditUserLogStatus.Pass;
      return x;
    });
    console.log(postData);
    api.helpApplies.batchAudit({body: {list: postData}})
      .then(() => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.fetchData(this.queryForm);
      });
  }

  handleBatchReject() {
    (this.backDialogBatch as any).show = true;
  }

  backedBatch(text: string) {
    let postData = this.selection.map((x) => {
      x.desc = text;
      x.status = AuditUserLogStatus.Reject;
      return x;
    });
    console.log(postData);
    api.helpApplies.batchAudit({body: {list: postData}})
      .then(() => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.fetchData(this.queryForm);
      });
  }
}
